import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Inventory } from 'src/app/interfaces/inventory.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { InventoriesService } from 'src/app/providers/inventories.service';
import { MomentDatesService } from 'src/app/providers/moment-dates.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  inventories: Inventory[] = [];
  loading: boolean;

  constructor(
    private _inventoriesService: InventoriesService,
    private _alertsService: AlertsService,
    private _momentDatesService: MomentDatesService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.getInitData();
  }

  async getInitData(): Promise<void> {
    try {
      this.loading = true;
      this.inventories = await this._inventoriesService.getInventories();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert(
        'error',
        'No se cargaron los datos correctamente...'
      );
    }
  }

  async createInventory(): Promise<void> {
    try {
      const alertConfirmation = await this._alertsService.confirmation(
        'Vas a crear un nuevo inventario'
      );
      if (alertConfirmation.isConfirmed) {
        this._alertsService.loading();
        const newInventory: Inventory = {
          status: 1,
          totalModels: 0,
          totalUnits: 0,
          totalInvestmentValue: 0,
          totalValue: 0,
          expectedInvestmentValue: 0,
          expectedTotalValue: 0,
          createdAt: new Date().getTime(),
        };
        await this._inventoriesService.addInventory(newInventory);
        await this.getInitData();
        this._alertsService.toastAlert('success', 'Nuevo inventario creado...');
      }
    } catch (error) {
      this._alertsService.toastAlert(
        'error',
        error.message ? error.message : 'Hubo un error...'
      );
    }
  }

  async deleteInventory(inventoryUid): Promise<void> {
    try {
      const alertConfirmation = await this._alertsService.confirmation(
        'Vas a eliminar este inventario'
      );
      if (alertConfirmation.isConfirmed) {
        await this._inventoriesService.deleteInventory(inventoryUid);
        await this.getInitData();
        this._alertsService.toastAlert(
          'success',
          'Inventario eliminado correctamente.'
        );
      }
    } catch (error) {
      this._alertsService.toastAlert('error', error.message);
    }
  }

  getDateWithMoment(date: Date | number, format: string): string {
    return this._momentDatesService.getDateWithMoment(date, format);
  }

  viewInventoryDetails(inventoryUid: string): void {
    this._router.navigate(['/admin/inventories', inventoryUid]);
  }
}
