import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { MovementsComponent } from './movements/movements.component';
import { ProductsComponent } from './products/products.component';
import { StockSummaryComponent } from './stock-summary/stock-summary.component';
import { CategoriesComponent } from './categories/categories.component';
import { BrandsComponent } from './brands/brands.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { CouponsComponent } from './coupons/coupons.component';
import { SalesComponent } from './sales/sales.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryDetailComponent } from './inventory-detail/inventory-detail.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: AdminMenuComponent },
      { path: 'movements', component: MovementsComponent },
      { path: 'sales', component: SalesComponent },
      { path: 'products', component: ProductsComponent },
      { path: 'inventories', component: InventoryComponent },
      { path: 'inventories/:uid', component: InventoryDetailComponent },
      { path: 'summary', component: StockSummaryComponent },
      { path: 'categories', component: CategoriesComponent },
      { path: 'brands', component: BrandsComponent },
      { path: 'coupons', component: CouponsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
