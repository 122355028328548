import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brand } from 'src/app/interfaces/brand.interface';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { InventoryRecord } from 'src/app/interfaces/inventory-record.interface';
import { Product } from 'src/app/interfaces/product.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { BrandsService } from 'src/app/providers/brands.service';
import { CategoriesService } from 'src/app/providers/categories.service';
import { InventoriesService } from 'src/app/providers/inventories.service';
import { MomentDatesService } from 'src/app/providers/moment-dates.service';
import { ProductsService } from 'src/app/providers/products.service';
import { MainCategory } from '../../interfaces/main-categorie.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Inventory } from 'src/app/interfaces/inventory.interface';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.scss'],
})
export class InventoryDetailComponent implements OnInit {
  inventoryUid: string;
  inventory: Inventory;
  inventoryRecords: InventoryRecord[] = [];
  inventoryRecordSelected: InventoryRecord;
  loading: boolean;
  products: Product[] = [];
  brands: Brand[] = [];
  categories: Categorie[] = [];
  mainCategories: MainCategory[] = [];
  sortColumn: string = '';
  sortDirection: boolean = true;

  constructor(
    private _momentDatesService: MomentDatesService,
    private _inventoriesService: InventoriesService,
    private _route: ActivatedRoute,
    private _alertsService: AlertsService,
    private _productsService: ProductsService,
    private _brandsService: BrandsService,
    private _categoriesService: CategoriesService,
    private _modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe((params) => {
      this.inventoryUid = params.get('uid');
      this.getInitData();
    });
  }

  async getInitData(): Promise<void> {
    try {
      this.loading = true;
      this.inventory = await this._inventoriesService.getInventory(
        this.inventoryUid
      );
      this.brands = await this._brandsService.getBrandsPromise();
      this.categories = await this._categoriesService.getCategoriesPromise();
      this.mainCategories = await this._categoriesService.getMainCategories();
      this.products = await this._productsService.getProductsLikePromise();
      this.products = this.products
        .map((p) => {
          if (!p) return null;

          const categoryName = this._categoriesService.getCategorieName(
            p.categorieUid,
            this.categories
          );
          const brandName = this._brandsService.getBrandName(
            p.brandUid,
            this.brands
          );

          return {
            ...p,
            nameCopy: p.name,
            name: `<span class="badge bg-light text-dark">${categoryName}</span>
                 <span class="badge bg-secondary text-white">${brandName}</span> ${p.name}`,
          };
        })
        .filter((p) => p !== null);

      this.inventoryRecords =
        await this._inventoriesService.getInventoryRecords(this.inventoryUid);
      this.updateInventoryStockMetrics();

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', error.message);
    }
  }

  async reloadInventoryRecords(): Promise<void> {
    try {
      this.loading = true;
      this.inventoryRecords =
        await this._inventoriesService.getInventoryRecords(this.inventoryUid);
      this.updateInventoryStockMetrics();

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', error.message);
    }
  }

  getDateWithMoment(dateParam: Date | number, format: string): string {
    const date: number = new Date(dateParam).getTime();
    return this._momentDatesService.getDateWithMoment(date, format);
  }

  async deleteInventoryRecordByUid(uid: string): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        '¿Estás seguro de querer eliminar este registro?'
      );
      if (alertResponse.value) {
        this.loading = true;
        await this._inventoriesService.deleteInventoryRecordByUid(uid);
        this.loading = false;
        this.reloadInventoryRecords();
      }
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', error.message);
    }
  }

  updateInventoryStockMetrics(): void {
    this.inventoryRecords = this.inventoryRecords.map((record) => {
      const stockDifference = record.physicalStock - record.theoreticalStock;

      return {
        ...record,
        stockDifference,
        stockDeficit:
          stockDifference < 0
            ? Math.abs(stockDifference) * record.salePrice
            : 0,
        stockSurplus:
          stockDifference > 0 ? stockDifference * record.salePrice : 0,
      };
    });
  }

  inventoryRecordDetail(content: any, record: InventoryRecord): void {
    this._modalService.open(content, { size: 'sm', backdrop: 'static' });
    const inventoryRecordExists: InventoryRecord = this.inventoryRecords.find(
      (ir) =>
        ir.productUid === record.productUid &&
        ir.inventoryUid === this.inventoryUid
    );
    if (inventoryRecordExists) {
      this.inventoryRecordSelected = inventoryRecordExists;
    }
  }

  closeAddProductToInventoryModal(): void {
    this._modalService.dismissAll();
    this.reloadInventoryRecords();
  }

  closeStockFormModal(event: boolean): void {
    if (event === true) {
      this.closeAddProductToInventoryModal();
    }
  }

  async updateInventoryData(inventoryUid: string): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        '¿Estás seguro de querer actualizar?'
      );
      if (alertResponse.value) {
        this.loading = true;

        const inventoryRecords = this.inventoryRecords.filter(
          (record) => record.inventoryUid === inventoryUid
        );

        if (inventoryRecords.length === 0) {
          throw new Error('No hay registros asociados a este inventario.');
        }

        const totalModels = inventoryRecords.length;
        const totalUnits = inventoryRecords.reduce(
          (sum, record) => sum + record.physicalStock,
          0
        );

        const totalInvestmentValue = inventoryRecords.reduce(
          (sum, record) => sum + record.physicalStock * record.purchasePrice,
          0
        );

        const totalValue = inventoryRecords.reduce(
          (sum, record) => sum + record.physicalStock * record.salePrice,
          0
        );

        const expectedInvestmentValue = inventoryRecords.reduce(
          (sum, record) => sum + record.theoreticalStock * record.purchasePrice,
          0
        );

        const expectedTotalValue = inventoryRecords.reduce(
          (sum, record) => sum + record.theoreticalStock * record.salePrice,
          0
        );

        const stockResult = totalValue - expectedTotalValue;

        const stockSurplus = stockResult > 0 ? stockResult : 0;
        const stockDeficit = stockResult < 0 ? Math.abs(stockResult) : 0;

        this.inventory = {
          ...this.inventory,
          totalModels,
          totalUnits,
          totalInvestmentValue,
          totalValue,
          expectedInvestmentValue,
          expectedTotalValue,
          stockSurplus,
          stockDeficit,
          updatedAt: Date.now(),
        };

        this.loading = false;

        await this._inventoriesService.updateInventory(
          this.inventoryUid,
          this.inventory
        );
        this._alertsService.toastAlert(
          'success',
          'Inventario actualizado correctamente.'
        );
      }
    } catch (error) {
      this.loading = false;

      this._alertsService.toastAlert(
        'error',
        'No se pudo actualizar el inventario.'
      );
    }
  }

  async updateInventoryStatus(): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        `¿Estás seguro de querer ${
          this.inventory.status === 1 ? 'cerrar' : 'abrir'
        } este inventario?`
      );
      if (alertResponse.value) {
        this.loading = true;
        this.inventory = {
          ...this.inventory,
          status: this.inventory.status === 1 ? 2 : 1,
        };
        await this._inventoriesService.updateInventory(
          this.inventoryUid,
          this.inventory
        );
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert(
        'error',
        `No se pudo ${
          this.inventory.status === 1 ? 'cerrar' : 'abrir'
        } el inventario.`
      );
    }
  }

  getTotalDeficit(): number {
    return this.inventoryRecords.reduce(
      (sum, record) => sum + (record.stockDeficit || 0),
      0
    );
  }

  getTotalSurplus(): number {
    return this.inventoryRecords.reduce(
      (sum, record) => sum + (record.stockSurplus || 0),
      0
    );
  }

  sortTable(column: string): void {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = true;
    }

    this.inventoryRecords.sort((a, b) => {
      const valueA = a[column] ?? 0;
      const valueB = b[column] ?? 0;

      return this.sortDirection ? valueA - valueB : valueB - valueA;
    });
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return 'fa-sort'; // Ícono por defecto
    }
    return this.sortDirection ? 'fa-sort-up' : 'fa-sort-down';
  }

  async updateInventoryDetailAdjustment(
    inventoryRecord: InventoryRecord
  ): Promise<void> {
    try {
      const alertResponse: any = await this._alertsService.confirmation(
        `¿Estás seguro de querer realizar este ajuste?`
      );
      if (alertResponse.value) {
        const inventoryRecordUpdated: InventoryRecord = {
          ...inventoryRecord,
          updated: new Date().getTime(),
        };
        await this._inventoriesService.updateInventoryRecord(
          inventoryRecord.uid,
          inventoryRecordUpdated
        );
        this._alertsService.toastAlert('success', `Ajuste realizado.`);
      } else {
        this.updateInventoryRecord(inventoryRecord.uid, {
          ...inventoryRecord,
          adjustment: inventoryRecord.adjustment === true ? false : true,
        });
      }
    } catch (error) {
      this.updateInventoryRecord(inventoryRecord.uid, {
        ...inventoryRecord,
        adjustment: inventoryRecord.adjustment === true ? false : true,
      });
      this._alertsService.toastAlert('error', `No se pudo realizar el ajuste.`);
    }
  }

  updateInventoryRecord(
    uid: string,
    updatedData: Partial<InventoryRecord>
  ): void {
    const index = this.inventoryRecords.findIndex(
      (record) => record.uid === uid
    );
    if (index !== -1) {
      this.inventoryRecords[index] = {
        ...this.inventoryRecords[index],
        ...updatedData,
      };
    }
  }
}
