import { Injectable } from '@angular/core';
import { Movement } from '../interfaces/movement';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { Filter } from '../interfaces/filter.interface';
import { Product } from '../interfaces/product.interface';
import { MovementsTotals } from '../interfaces/movements-totals.interface';
import { CategoriesService } from './categories.service';

@Injectable({
  providedIn: 'root',
})
export class MovementsService {
  private movementsCollection: AngularFirestoreCollection<Movement>;

  constructor(
    private _afs: AngularFirestore,
    private _categoriesService: CategoriesService
  ) {
    this.movementsCollection = _afs.collection<Movement>('movements', (ref) =>
      this.returnQuery(ref, 10)
    );
  }

  async addMovement(movement: any): Promise<void> {
    const id = this._afs.createId();

    const movementToSave: Movement = {
      uid: id,
      ...movement,
    };
    this.movementsCollection.doc(id).set(movementToSave);
  }

  editMovement(movement: Movement) {
    return this.movementsCollection.doc(movement.uid).update(movement);
  }

  async editMovementPromise(movement: Movement): Promise<void> {
    try {
      await this.movementsCollection.doc(movement.uid).update(movement);
    } catch (error) {
      throw error;
    }
  }

  getMovements(): Observable<Movement[]> {
    return this.movementsCollection.valueChanges();
  }

  getMovementsWithPagination(
    pageSize: number,
    filters: Filter[],
    prev?: number | null,
    next?: number | null
  ): Observable<Movement[]> {
    let query = this._afs.collection<Movement>('movements', (ref: any) => {
      ref = ref.orderBy('created', 'desc').limit(pageSize);

      if (prev !== null) {
        ref = ref.startAt(prev);
      }

      if (next !== null) {
        ref = ref.startAfter(next);
      }

      for (const filter of filters) {
        ref = ref.where(filter.field, filter.operator, filter.value);
      }

      return ref;
    });

    return query.valueChanges().pipe(take(1));
  }

  getMovementsNoPagination(
    filters: Filter[],
    prev?: number | null,
    next?: number | null
  ): Observable<Movement[]> {
    let query = this._afs.collection<Movement>('movements', (ref: any) => {
      ref = ref.orderBy('created', 'desc');

      if (prev !== null) {
        ref = ref.startAt(prev);
      }

      if (next !== null) {
        ref = ref.startAfter(next);
      }

      for (const filter of filters) {
        ref = ref.where(filter.field, filter.operator, filter.value);
      }

      return ref;
    });

    return query.valueChanges().pipe(take(1));
  }

  returnQuery(ref, pageSize): any {
    return ref.orderBy('created', 'desc').limit(pageSize);
  }

  deleteMovement(id: string) {
    return this.movementsCollection.doc(id).delete();
  }

  getMovementsByProductUid(productUid: string): Observable<Movement[]> {
    return this._afs
      .collection<Movement>('movements', (ref) =>
        ref.where('productUid', '==', productUid)
      )
      .valueChanges();
  }

  getMovementsByProductUidOnce(productUid: string): Promise<Movement[]> {
    return new Promise((resolve, reject) => {
      this._afs
        .collection<Movement>('movements', (ref) =>
          ref.where('productUid', '==', productUid)
        )
        .get()
        .subscribe(
          (querySnapshot) => {
            const movements: Movement[] = [];
            querySnapshot.docs.forEach((doc) => {
              movements.push(doc.data() as Movement);
            });
            resolve(movements);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getCurrentInvestesment(products: Product[], movs: Movement[]) {
    const valuesByProduct: number[] = [];
    const expectedSaleByProduct: number[] = [];
    const entriesArray: number[] = [];
    const removalsArray: number[] = [];
    const salesArray: number[] = [];
    const canceledArray: number[] = [];

    products.map((p) => {
      const productMovements: Movement[] = movs.filter(
        (m) => m.productUid === p.uid
      );
      const productMovementsEntries = productMovements.filter(
        (m) => m.type === 1
      );
      if (productMovementsEntries.length > 0) {
        const entries: number = this.getItemsNumber(productMovementsEntries);
        const removals: number = this.getItemsNumber(
          productMovements.filter((m) => m.type === 2)
        );
        const sales: number = this.getItemsNumber(
          productMovements.filter((m) => m.type === 3)
        );
        const canceled: number = this.getItemsNumber(
          productMovements.filter((m) => m.type === 4)
        );
        const stock: number = entries - removals - sales;
        const productInvesment =
          stock *
          Number(
            this.sortArrayByCreatedDate(
              productMovements.filter((m) => m.type === 1)
            )[0].costByUnit
          );
        const productExpectedSale = stock * Number(p.price);
        valuesByProduct.push(productInvesment);
        expectedSaleByProduct.push(productExpectedSale);
        entriesArray.push(entries);
        removalsArray.push(removals);
        salesArray.push(sales);
        canceledArray.push(canceled);
      }
    });
    let results: any = {};
    results.currentInvestment = valuesByProduct.reduce(function (ac, cv) {
      return Number(ac) + Number(cv);
    }, 0);
    results.expectedSales = expectedSaleByProduct.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
    results.productsEntries = this.getTotal(entriesArray);
    results.productsRemovals = this.getTotal(removalsArray);
    results.productsSales = this.getTotal(salesArray);
    results.productsCanceled = this.getTotal(canceledArray);

    return results;
  }

  getProductsArrayWithInvestment(
    products: Product[],
    movs: Movement[]
  ): Product[] {
    return products.map((p) => {
      const productMovements: Movement[] = movs.filter(
        (m) => m.productUid === p.uid
      );
      const productMovementsEntries = productMovements.filter(
        (m) => m.type === 1
      );
      if (productMovementsEntries.length > 0) {
        const entries: number = this.getItemsNumber(productMovementsEntries);
        const removals: number = this.getItemsNumber(
          productMovements.filter((m) => m.type === 2)
        );
        const sales: number = this.getItemsNumber(
          productMovements.filter((m) => m.type === 3)
        );
        const stock: number = entries - removals - sales;
        const productInvesment =
          stock *
          Number(
            this.sortArrayByCreatedDate(
              productMovements.filter((m) => m.type === 1)
            )[0].costByUnit
          );

        return { ...p, currentInvestment: productInvesment };
      }
    });
  }

  getItemsNumber(movementsArray: Movement[]): number {
    const items: number[] = [];

    movementsArray.map((m) => items.push(m.productsNumber));

    const value: number = items.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);

    return value;
  }

  getTotal(array: number[]): number {
    return array.reduce(function (ac, cv) {
      return ac + cv;
    }, 0);
  }

  public sortArrayByCreatedDate(array: Movement[]): Movement[] {
    return array.sort((a, b) => b.created - a.created);
  }

  mapMovements(movement: Movement, i: number, products: Product[]): Movement {
    return {
      ...movement,
      uid: !movement.uid ? movement.id : movement.uid,
      costByUnit: Number(movement.costByUnit),
      productsNumber: Number(movement.productsNumber),
      type: Number(movement.type),
      index: i,
      categorieUid: this._categoriesService.getCategorieUid(
        movement.productUid,
        products
      ),
    };
  }

  async saveMovementsByTicket(
    movements: Movement[],
    ticketUid: string
  ): Promise<void> {
    try {
      const promises: Promise<void>[] = movements.map(async (mov) => {
        const newMov: Movement = { ...mov, ticketUid: ticketUid };
        await this.movementsCollection.doc(newMov.uid).set(newMov);
        // console.log('Registro agregado');
      });

      await Promise.all(promises);
      // console.log('Todos los registros fueron guardados con éxito.');
    } catch (error) {
      // console.error('Error al guardar registros:', error);
      throw error;
    }
  }

  async getMovementsByTicketUid(ticketUid: string): Promise<Movement[]> {
    try {
      const collectionRef = 'movements';
      const querySnapshot: any = await this._afs
        .collection(collectionRef, (ref) =>
          ref.where('ticketUid', '==', ticketUid)
        )
        .get()
        .toPromise();

      if (!querySnapshot.empty) {
        const ticketArray = querySnapshot.docs.map(
          (doc) => doc.data() as Movement
        );
        return ticketArray;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  }

  async getLatestMovement(
    type: number,
    productUid: string
  ): Promise<Movement | null> {
    try {
      const querySnapshot = await this._afs
        .collection<Movement>('movements', (ref) =>
          ref
            .where('type', '==', type)
            .where('productUid', '==', productUid)
            .orderBy('created', 'desc')
            .limit(1)
        )
        .get()
        .toPromise();

      if (querySnapshot.empty) {
        return null;
      }

      return querySnapshot.docs[0].data() as Movement;
    } catch (error) {
      throw error;
    }
  }
}
