<app-back-button></app-back-button>
<div class="d-flex justify-content-end pt-2 pb-2">
  <button
    class="btn btn-primary"
    [ngClass]="{ 'btn-secondary': productContainer }"
    (click)="openProductContainer()"
  >
    {{
      productContainer
        ? "Cerrar"
        : "Nuevo
        producto"
    }}
  </button>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar producto</h4>
    <button
      type="button"
      class="btn btn-dark btn-sm"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="fa-solid fa-circle-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="container" *ngIf="product !== null">
      <form [formGroup]="productForm">
        <div class="row mb-1">
          <div class="col-md-3 mb-2">
            <input
              class="form-control"
              type="text"
              id="productName"
              placeholder="Nombre del producto..."
              formControlName="name"
            />
          </div>
          <div class="col-md-3 mb-2">
            <app-category-select
              (sendData)="product.categorieUid = $event"
              [categorieSelected]="product.categorieUid"
            ></app-category-select>
          </div>
          <div class="col-md-2 mb-2">
            <app-brand-select
              (sendData)="product.brandUid = $event"
              [brandSelected]="product.brandUid"
            >
            </app-brand-select>
          </div>
          <div class="col-md-2 mb-2">
            <input
              class="form-control"
              type="text"
              id="productPrice"
              placeholder="Precio..."
              formControlName="price"
            />
          </div>
          <div class="col-md-2 mb-2">
            <input
              class="form-control"
              type="text"
              id="productCode"
              placeholder="Código..."
              formControlName="code"
            />
          </div>
          <!-- <div class="col-md-2 mb-2">
                        <div class="row">
                            <div class="col-6 pt-2">
                                <span class="fs-6 fw-bold">En rebaja:</span>
                            </div>
                            <div class="col-6">
                                <div class="d-flex justify-content-center">
                                    <span style="cursor: pointer;">
                                        <i class="fa-solid fa-toggle-on fa-3x text-success"
                                            (click)="product.offSale = false" *ngIf="product?.offSale"></i>
                                        <i class="fa-solid fa-toggle-off fa-3x text-secondary"
                                            (click)="product.offSale = true" *ngIf="!product?.offSale"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <div class="col-md-12 mb-2">
            <angular-editor
              [placeholder]="'Descripción...'"
              formControlName="description"
            >
            </angular-editor>
          </div>
        </div>
      </form>
      <div class="row d-flex justify-content-center">
        <div
          class="col-6 d-flex justify-content-end"
          style="display: flex; align-items: center"
        >
          <label for="editnotCountInInventory" class="fw-bold"
            >No guardar movimientos:</label
          >
        </div>
        <div class="col-6">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="editnotCountInInventory"
              [(ngModel)]="product.notCountInInventory"
              style="cursor: pointer"
              [checked]="product?.notCountInInventory"
            />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <select
              class="form-control"
              type="text"
              id="colors"
              [(ngModel)]="colorSelected"
            >
              <option value="" selected>Colores</option>
              <option
                *ngFor="let color of colors | sortByLetter"
                [value]="color.uid"
              >
                {{ color.name }}
              </option>
            </select>
            <br />
            <div class="row justify-content-center">
              <ng-container *ngIf="colorSelected !== ''">
                <div class="w-50">
                  <span
                    class="badge rounded-pill me-2"
                    [ngStyle]="{
                      'background-color': getHexCode(colorSelected),
                      color: getTextColor(colorSelected)
                    }"
                  >
                    {{ getColorName(colorSelected) }}
                  </span>
                  <button
                    class="btn btn-light btn-sm"
                    (click)="addColor(colorSelected)"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-8">
            <ng-container *ngFor="let color of product.colors">
              <span
                class="badge rounded-pill me-2"
                [ngStyle]="{
                  'background-color': getHexCode(color),
                  color: getTextColor(color)
                }"
              >
                {{ getColorName(color) }}
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 mb-2">
        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary"
            (click)="saveProduct(true)"
            [disabled]="
              !productForm.valid ||
              product.categorieUid === '' ||
              product === null
            "
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #galleryContent let-modal>
  <app-product-gallery [modal]="modal" [product]="productSelected">
  </app-product-gallery>
</ng-template>
<div class="container" *ngIf="productContainer">
  <form [formGroup]="productForm">
    <div class="row mb-1">
      <div class="col-md-3 mb-2">
        <input
          class="form-control"
          type="text"
          id="productName"
          placeholder="Nombre del producto..."
          formControlName="name"
          autocomplete="off"
        />
      </div>
      <div class="col-md-3 mb-2">
        <app-category-select
          [categorieSelected]="product.categorieUid"
          (sendData)="product.categorieUid = $event"
        ></app-category-select>
      </div>
      <div class="col-md-2 mb-2">
        <app-brand-select
          [brandSelected]="product.brandUid"
          (sendData)="product.brandUid = $event"
        >
        </app-brand-select>
      </div>
      <div class="col-md-2 mb-2">
        <input
          class="form-control"
          type="text"
          id="productPrice"
          placeholder="Precio..."
          formControlName="price"
          autocomplete="off"
        />
      </div>
      <div class="col-md-2 mb-2">
        <input
          class="form-control"
          type="text"
          id="productCode"
          placeholder="Código..."
          formControlName="code"
        />
      </div>
      <!-- <div class="col-md-2 mb-2">
                <div class="row">
                    <div class="col-3 pt-2">
                        <span class="fs-6 fw-bold">Mostrar:</span>
                    </div>
                    <div class="col-9">
                        <div class="d-flex justify-content-center">
                            <span style="cursor: pointer;">
                                <i class="fa-solid fa-toggle-on fa-3x text-success" (click)="product.show = false"
                                    *ngIf="product.show"></i>
                                <i class="fa-solid fa-toggle-off fa-3x text-secondary" (click)="product.show = true"
                                    *ngIf="!product.show"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->
      <div class="col-md-12 mb-2">
        <angular-editor
          [placeholder]="'Descripción...'"
          formControlName="description"
        >
        </angular-editor>
      </div>
    </div>
  </form>
  <div class="row d-flex justify-content-center">
    <div
      class="col-6 d-flex justify-content-end"
      style="display: flex; align-items: center"
    >
      <label for="editnotCountInInventory" class="fw-bold"
        >No guardar movimientos:</label
      >
    </div>
    <div class="col-6">
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="editnotCountInInventory"
          [(ngModel)]="product.notCountInInventory"
          style="cursor: pointer"
          [checked]="product?.notCountInInventory"
        />
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-4">
        <select
          class="form-control"
          type="text"
          id="colors"
          [(ngModel)]="colorSelected"
        >
          <option value="" selected>Colores</option>
          <option
            *ngFor="let color of colors | sortByLetter"
            [value]="color.uid"
          >
            {{ color.name }}
          </option>
        </select>
        <br />
        <div class="row justify-content-center">
          <ng-container *ngIf="colorSelected !== ''">
            <div class="w-50">
              <span
                class="badge rounded-pill me-2"
                [ngStyle]="{
                  'background-color': getHexCode(colorSelected),
                  color: getTextColor(colorSelected)
                }"
              >
                {{ getColorName(colorSelected) }}
              </span>
              <button
                class="btn btn-light btn-sm"
                (click)="addColor(colorSelected)"
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-8">
        <ng-container *ngFor="let color of product.colors">
          <span
            class="badge rounded-pill me-2"
            [ngStyle]="{
              'background-color': getHexCode(color),
              color: getTextColor(color)
            }"
          >
            {{ getColorName(color) }}
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end mb-3" *ngIf="productContainer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="saveProduct()"
    [disabled]="!productForm.valid || product.categorieUid === '' || loading"
  >
    Guardar
  </button>
</div>

<div class="container mt-2 mb-2">
  <div class="buttons-container">
    <button
      type="button"
      *ngFor="let cat of categories | sortByLetter; let i = index"
      class="btn btn-outline-primary"
      (click)="filterByCategories(cat.uid)"
      [ngClass]="{ active: categorieUidSelected === cat.uid, 'btn-sm': mobile }"
    >
      {{ cat.name }}
    </button>
  </div>

  <div class="d-flex justify-content-end mt-2 mb-2">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        id="showOutOfStockCheck"
        [(ngModel)]="showOutOfStock"
        (change)="getProductsByCategoryUid()"
        style="cursor: pointer"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 pb-5">
      <div class="table-responsive mb-t">
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
          <table class="table table-ligth table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th></th>
                <th scope="col">Nombre</th>
                <th scope="col">Precio</th>
                <th scope="col text-center">
                  <i class="fas fa-clipboard" style="font-size: 1.4rem"></i>
                </th>
                <th>
                  <i class="fas fa-tag" style="font-size: 1.4rem"></i>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="loading === false">
              <tr
                *ngFor="let product of products | sortByLetter; let i = index"
              >
                <th scope="row">
                  <i
                    class="fa-solid fa-circle ms-2"
                    style="cursor: pointer"
                    (click)="
                      editOnePropertyValueOfProduct(
                        product,
                        'show',
                        product?.show === true ? false : true
                      )
                    "
                    [ngClass]="{
                      'text-success': product && product.show,
                      'text-danger': product && !product.show
                    }"
                  ></i>
                  {{ i + 1 }}
                </th>
                <td>
                  <img
                    [src]="
                      product.photoUrl && product.photoUrl.length > 1
                        ? product.photoUrl
                        : '../../../assets/images/no-image-small.jpg'
                    "
                    alt="profile image"
                    class="img-fluid rounded-circle border-3"
                    style="width: 35px; height: 35px"
                  />
                </td>
                <td [innerHtml]="product.name">
                  {{ product.name }}
                </td>
                <td>{{ product.price | currency }}</td>
                <td>
                  <div class="col-md-12 mt-3">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="markAsOutOfStockInput"
                        (change)="markAsOutOfStock(product, $event)"
                        style="cursor: pointer"
                        [checked]="product?.outOfStock"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="col-md-12 mt-3">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="editOffSale"
                        (change)="
                          editOnePropertyValueOfProduct(
                            product,
                            'offSale',
                            $event.target.checked
                          )
                        "
                        style="cursor: pointer"
                        [checked]="product?.offSale"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    class="btn btn-info btn-sm me-2 mbSm"
                    (click)="openModalEdit(content, product)"
                  >
                    <i class="fa-solid fa-pen-to-square"></i>
                  </button>
                  <button
                    class="btn btn-light btn-sm me-2 mbSm"
                    (click)="openProductGalleryModal(galleryContent, product)"
                  >
                    <i class="fa-solid fa-image"></i>
                  </button>
                  <!-- <button class="btn btn-danger btn-sm" (click)="deleteProduct(product.uid)">
                                        <i class="fa-solid fa-trash"></i>
                                    </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
