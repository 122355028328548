import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Categorie } from 'src/app/interfaces/categorie.interface';
import { InventoryRecord } from 'src/app/interfaces/inventory-record.interface';
import { Movement } from 'src/app/interfaces/movement';
import { Product } from 'src/app/interfaces/product.interface';
import { AlertsService } from 'src/app/providers/alerts.service';
import { InventoriesService } from 'src/app/providers/inventories.service';
import { MovementsService } from 'src/app/providers/movements.service';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  styleUrls: ['./stock-form.component.scss'],
})
export class StockFormComponent implements OnInit {
  @Input() inventoryRecord: InventoryRecord;
  @Input() categories: Categorie[];
  @Output() closeStockFormModal: EventEmitter<boolean> = new EventEmitter();
  product: Product;
  movement: Movement;
  loading: boolean;
  error: boolean;

  constructor(
    private _productsService: ProductsService,
    private _movementsService: MovementsService,
    private _inventoriesService: InventoriesService,
    private _alertsService: AlertsService,
    private _afs: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.loadProductData();
  }

  async loadProductData(): Promise<void> {
    try {
      this.loading = true;
      this.product = await this._productsService.getProductByUidPromise(
        this.inventoryRecord.productUid
      );
      this.inventoryRecord.salePrice = Number(this.product.price);
      this.movement = await this._movementsService.getLatestMovement(
        1,
        this.product.uid
      );

      this.inventoryRecord.purchasePrice = this.movement.costByUnit;
      this.inventoryRecord.mainCategoryUid = this.getMainCategoryUidByUid(
        this.inventoryRecord.categoryUid
      );

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.error = true;
      this._alertsService.toastAlert(
        'error',
        'No se pudo cargar la información'
      );
    }
  }

  async saveInventoryRecord(): Promise<void> {
    try {
      this.loading = true;
      this.inventoryRecord = {
        ...this.inventoryRecord,
        created: new Date().getTime(),
        uid: this._afs.createId(),
      };
      await this._inventoriesService.addInventoryRecord(this.inventoryRecord);
      this._alertsService.toastAlert(
        'success',
        'Registro guardado correctamente...'
      );
      this.closeStockFormModal.emit(true);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', error.message);
    }
  }

  async updateInventoryRecord(): Promise<void> {
    try {
      this.loading = true;
      this.inventoryRecord = {
        ...this.inventoryRecord,
        updated: new Date().getTime(),
      };
      await this._inventoriesService.updateInventoryRecord(
        this.inventoryRecord.uid,
        this.inventoryRecord
      );
      this._alertsService.toastAlert('success', 'Actualizado correctamente...');
      this.closeStockFormModal.emit(true);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this._alertsService.toastAlert('error', error.message);
    }
  }

  getMainCategoryNameByUid(mainCategoryUid: string): string {
    const category = this.categories.find(
      (cat) => cat.mainCategoryUid === mainCategoryUid
    );
    return category ? category.mainCategoryName : 'Nombre no encontrado';
  }

  getCategoryNameByUid(categoryUid: string): string {
    const category = this.categories.find((cat) => cat.uid === categoryUid);
    return category ? category.name : 'Nombre no encontrado';
  }

  getMainCategoryUidByUid(categoryUid: string): string {
    const category = this.categories.find((cat) => cat.uid === categoryUid);
    return category ? category.mainCategoryUid : '';
  }
}
