<select
  class="form-control"
  type="text"
  id="productSelect"
  [(ngModel)]="productSelected"
  (change)="sendData.emit(productSelected)"
  [disabled]="categoryUid === '' || products.length === 0"
  [disabled]="disabled"
>
  <option value="" *ngIf="productSelected === ''">Producto</option>
  <option *ngFor="let p of products | sortByLetter" [value]="p.uid">
    {{ p.name }}
  </option>
</select>
