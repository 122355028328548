import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Product } from '../../../interfaces/product.interface';
import { ProductsService } from '../../../providers/products.service';
import { BrandsService } from '../../../providers/brands.service';
import { Brand } from '../../../interfaces/brand.interface';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
})
export class ProductSelectComponent implements OnInit {
  products: Product[] = [];
  productsCopy: Product[] = [];
  @Input() productSelected: string = '';
  brands: Brand[] = [];
  @Input() categoryUid: string = '';
  @Input() disabled: boolean;
  @Output() sendData: EventEmitter<string> = new EventEmitter();

  constructor(
    private _productsService: ProductsService,
    private _brandsService: BrandsService
  ) {}

  ngOnInit(): void {
    this.getBrands();
    this.getProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.categoryUid !== '') {
      if (changes && changes.categoryUid && changes.categoryUid.currentValue) {
        this.products = this.productsCopy.filter(
          (p) => p.categorieUid === changes.categoryUid.currentValue
        );
      }
      // console.log(this.products, 'products');
    } else {
      this.productSelected = '';
    }
  }

  getProducts() {
    this._productsService.getProducts().subscribe((products) => {
      this.products = products;
      this.productsCopy = products.map((p) => {
        const brandName = this.getBrandName(p.brandUid);
        return {
          ...p,
          name: brandName + `${brandName.length > 0 ? ' - ' : ''}` + p.name,
        };
      });
      if (this.categoryUid !== '') {
        this.products = this.productsCopy.filter(
          (p) => p.categorieUid === this.categoryUid
        );
        // console.log(this.products, 'products');
      }
    });
  }

  getBrandName(brandUid: string): string {
    const brand = this.brands.find((b) => b.uid === brandUid);
    if (brand && brand.name) {
      return brand.name;
    }
    return '';
  }

  getBrands() {
    this._brandsService.getBrands().subscribe((brands) => {
      this.brands = brands;
    });
  }
}
