<select
  class="form-control"
  type="text"
  id="productCategory"
  [(ngModel)]="categorieSelected"
  (change)="sendData.emit(categorieSelected)"
  [disabled]="disabled"
>
  <option value="" *ngIf="categorieSelected === ''">Categoría</option>
  <option *ngFor="let cat of categories | sortByLetter" [value]="cat.uid">
    {{ cat.name }}
  </option>
</select>
