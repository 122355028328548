import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { Inventory } from '../interfaces/inventory.interface';
import { InventoryRecord } from '../interfaces/inventory-record.interface';

@Injectable({
  providedIn: 'root',
})
export class InventoriesService {
  private inventoriesCollection: AngularFirestoreCollection<Inventory>;
  private inventoryRecordsCollection: AngularFirestoreCollection<InventoryRecord>;

  constructor(private _afs: AngularFirestore) {
    this.inventoriesCollection = _afs.collection<Inventory>('inventories');
    this.inventoryRecordsCollection =
      _afs.collection<InventoryRecord>('inventoryRecords');
  }

  async addInventory(inventory: any): Promise<void> {
    const counterRef = this._afs
      .collection('inventoryCounters')
      .doc('inventoryCounter');

    const activeInventorySnapshot = await this._afs.firestore
      .collection('inventories')
      .where('status', '==', 1)
      .get();

    if (!activeInventorySnapshot.empty) {
      throw new Error(
        'No se puede crear un nuevo inventario porque ya hay uno en curso.'
      );
    }

    await this._afs.firestore.runTransaction(async (transaction) => {
      const counterDoc = await transaction.get(counterRef.ref);
      let newInventoryNumber = 1;

      if (counterDoc.exists) {
        const counterData = counterDoc.data() as {
          lastInventoryNumber?: number;
        };
        newInventoryNumber = (counterData.lastInventoryNumber || 0) + 1;
      }

      const uid = this._afs.createId();

      const inventoryToSave: Inventory = {
        uid: uid,
        inventoryNumber: newInventoryNumber,
        ...inventory,
      };

      const inventoryDocRef = this._afs.collection('inventories').doc(uid);
      transaction.set(inventoryDocRef.ref, inventoryToSave);

      transaction.set(
        counterRef.ref,
        { lastInventoryNumber: newInventoryNumber },
        { merge: true }
      );
    });
  }

  async getInventories(): Promise<Inventory[]> {
    const querySnapshot: any = await this.inventoriesCollection
      .get()
      .toPromise();
    if (!querySnapshot.empty) {
      const inventoriesArray = querySnapshot.docs.map(
        (doc) => doc.data() as Inventory
      );
      return inventoriesArray;
    } else {
      return [];
    }
  }

  async deleteInventory(inventoryUid: string): Promise<void> {
    const recordsRef = this._afs.collection('inventoryRecords', (ref) =>
      ref.where('inventoryUid', '==', inventoryUid)
    );

    const recordsSnapshot = await recordsRef.get().toPromise();

    if (!recordsSnapshot?.empty) {
      throw new Error(
        'No se puede eliminar el inventario porque tiene registros asociados.'
      );
    }

    await this._afs.collection('inventories').doc(inventoryUid).delete();
  }

  async getInventoriesByStatus(status: number): Promise<Inventory[]> {
    const querySnapshot = await this.inventoriesCollection.ref
      .where('status', '==', status)
      .get();

    if (!querySnapshot.empty) {
      return querySnapshot.docs.map((doc) => doc.data() as Inventory);
    } else {
      return [];
    }
  }

  async getInventoryRecords(inventoryUid: string): Promise<InventoryRecord[]> {
    const querySnapshot = await await this.inventoryRecordsCollection.ref
      .where('inventoryUid', '==', inventoryUid)
      .get();

    return querySnapshot.empty
      ? []
      : querySnapshot.docs.map((doc) => doc.data() as InventoryRecord);
  }
  catch(error) {
    throw new Error('Error obteniendo los registros del inventario.');
  }

  async getInventoryRecord(
    inventoryUid: string,
    productUid: string
  ): Promise<InventoryRecord | null> {
    try {
      const querySnapshot = await this.inventoryRecordsCollection.ref
        .where('inventoryUid', '==', inventoryUid)
        .where('productUid', '==', productUid)
        .limit(1)
        .get();

      return querySnapshot.empty
        ? null
        : (querySnapshot.docs[0].data() as InventoryRecord);
    } catch (error) {
      throw new Error('Error obteniendo el registro del inventario.');
    }
  }

  async addInventoryRecord(newRecord: InventoryRecord): Promise<void> {
    try {
      const existingRecord = await this.inventoryRecordsCollection.ref
        .where('productUid', '==', newRecord.productUid)
        .where('inventoryUid', '==', newRecord.inventoryUid)
        .limit(1)
        .get();

      if (!existingRecord.empty) {
        throw new Error('Ya existe este producto en el inventario');
      }

      await this.inventoryRecordsCollection.doc(newRecord.uid).set(newRecord);
    } catch (error) {
      throw new Error('No se pudo registrar.');
    }
  }

  async updateInventoryRecord(
    uid: string,
    updatedData: Partial<InventoryRecord>
  ): Promise<void> {
    try {
      await this.inventoryRecordsCollection.doc(uid).update(updatedData);
    } catch (error) {
      throw new Error('No se pudo actualizar el registro.');
    }
  }

  async deleteInventoryRecordByUid(uid: string): Promise<void> {
    try {
      await this.inventoryRecordsCollection.doc(uid).delete();
    } catch (error) {
      throw new Error('No se pudo eliminar el registro.');
    }
  }

  async getInventory(inventoryUid: string): Promise<Inventory | null> {
    try {
      const querySnapshot = await this.inventoriesCollection.ref
        .where('uid', '==', inventoryUid)
        .limit(1)
        .get();

      return querySnapshot.empty
        ? null
        : (querySnapshot.docs[0].data() as Inventory);
    } catch (error) {
      throw new Error('Error obteniendo el inventario.');
    }
  }

  async updateInventory(
    uid: string,
    updatedData: Partial<Inventory>
  ): Promise<void> {
    try {
      await this.inventoriesCollection.doc(uid).update(updatedData);
    } catch (error) {
      throw new Error('No se pudo actualizar el registro.');
    }
  }
}
