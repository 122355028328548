<form class="form-group">
  <input
    id="typeahead-focus"
    type="text"
    class="form-control"
    placeholder="Buscar por producto"
    [formControl]="mySearchEngineForm"
    [ngbTypeahead]="search"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    #instance="ngbTypeahead"
    (keyup.enter)="optionSelected($event)"
  />
</form>
