<div class="container">
  <table class="table table-bordered align-middle" *ngIf="!loading">
    <tbody>
      <tr>
        <th class="bg-light">Categoría Principal</th>
        <td class="fw-bold">
          {{ getMainCategoryNameByUid(inventoryRecord.mainCategoryUid) }}
        </td>
      </tr>
      <tr>
        <th class="bg-light w-50">Categoría</th>
        <td class="fw-bold">
          {{ getCategoryNameByUid(inventoryRecord.categoryUid) }}
        </td>
      </tr>
      <tr>
        <th class="bg-light">Precio Compra</th>
        <td class="fw-bold">{{ inventoryRecord.purchasePrice | currency }}</td>
      </tr>
      <tr>
        <th class="bg-light">Precio Venta</th>
        <td class="fw-bold">{{ inventoryRecord.salePrice | currency }}</td>
      </tr>
      <tr>
        <th class="bg-light">Stock Teórico</th>
        <td class="fw-bold">{{ inventoryRecord.theoreticalStock }}</td>
      </tr>
      <tr>
        <th class="bg-light">Stock Real</th>
        <td>
          <input
            type="number"
            class="form-control fw-bold"
            [(ngModel)]="inventoryRecord.physicalStock"
            min="0"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center">
    <button
      class="btn btn-success"
      [disabled]="
        error ||
        inventoryRecord.physicalStock < 0 ||
        inventoryRecord.physicalStock === undefined ||
        inventoryRecord.physicalStock === null
      "
      (click)="saveInventoryRecord()"
      *ngIf="!loading && !inventoryRecord.uid"
    >
      Registrar
    </button>

    <button
      class="btn btn-success"
      [disabled]="
        error ||
        inventoryRecord.physicalStock < 0 ||
        inventoryRecord.physicalStock === undefined ||
        inventoryRecord.physicalStock === null
      "
      (click)="updateInventoryRecord()"
      *ngIf="!loading && inventoryRecord.uid"
    >
      Actualizar
    </button>
  </div>
  <div class="mt-5 mb-5 pt-5 pb-5" *ngIf="loading">
    <div class="d-flex align-items-center">
      <strong>Loading...</strong>
      <div
        class="spinner-border ms-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>
</div>
