<div class="container">
  <div class="row" *ngIf="!loading">
    <div class="col-4">
      <div class="d-flex justify-content-start pt-2 pb-2">
        <div class="d-flex justify-content-start">
          <button class="btn btn-info btn-sm" routerLink="/admin/inventories">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-center pt-2 pb-2">
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary btn-sm" (click)="updateInventoryData(inventoryUid)"
            [disabled]="loading || inventory.status === 2">
            <i class="fas fa-sync"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-end pt-2 pb-2">
        <div class="d-flex justify-content-end">
          <button class="btn btn-sm" (click)="updateInventoryStatus()" [disabled]="loading" [ngClass]="{
              'btn-secondary': inventory.status === 1,
              'btn-dark': inventory.status === 2
            }">
            <i class="fas fa-lock-open" *ngIf="inventory.status === 1"></i>
            <i class="fas fa-lock" *ngIf="inventory.status === 2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading">
    <div class="d-flex align-items-center mt-2">
      <strong>Loading...</strong>
      <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
    </div>
  </div>
  <div class="table-responsive" *ngIf="!loading">
    <table class="table table-bordered">
      <tbody>
        <tr class="table-dark">
          <th>#</th>
          <th>Estado</th>
          <th>Fecha de Creación</th>
          <th>Última Actualización</th>
          <th>Modelos</th>
          <th>Unidades</th>
        </tr>
        <tr>
          <td class="fw-bold">{{ inventory.inventoryNumber }}</td>
          <td>
            <span class="badge" [ngClass]="{
                'bg-primary': inventory.status === 1,
                'bg-success': inventory.status === 2
              }">
              {{ inventory.status === 1 ? "En curso" : "Completado" }}
            </span>
          </td>
          <td>{{ inventory.createdAt | date : "dd/MM/yyyy" }}</td>
          <td>{{ inventory.updatedAt | date : "dd/MM/yyyy" }}</td>
          <td>{{ inventory.totalModels }}</td>
          <td>{{ inventory.totalUnits }}</td>
        </tr>

        <tr class="table-secondary">
          <th>Inversión Esperada ($)</th>
          <th>Valor Esperado en Venta ($)</th>
          <th>Total Invertido ($)</th>
          <th>Valor en Venta ($)</th>
          <th class="text-danger">Déficit de Stock ($)</th>
          <th class="text-success">Excedente de Stock ($)</th>
        </tr>
        <tr>
          <td>
            {{ inventory.expectedInvestmentValue | currency }}
          </td>
          <td>
            {{ inventory.expectedTotalValue | currency }}
          </td>
          <td>
            {{ inventory.totalInvestmentValue | currency }}
          </td>
          <td>{{ inventory.totalValue | currency }}</td>
          <td class="text-danger">
            {{ inventory.stockDeficit | currency }}
          </td>
          <td class="text-success">
            {{ inventory.stockSurplus | currency }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive" *ngIf="!loading">
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-bordered table-striped mt-2">
        <thead class="table-dark">
          <tr>
            <th>#</th>
            <th>Nombre producto</th>
            <!-- <th class="pointer" (click)="sortTable('purchasePrice')">
              Costo Unitario ($)
              <i class="fa-solid" [ngClass]="getSortIcon('purchasePrice')"></i>
            </th> -->
            <th class="pointer" (click)="sortTable('theoreticalStock')">
              Stock Teórico
              <i class="fa-solid" [ngClass]="getSortIcon('theoreticalStock')"></i>
            </th>
            <th class="pointer" (click)="sortTable('physicalStock')">
              Stock Físico
              <i class="fa-solid" [ngClass]="getSortIcon('physicalStock')"></i>
            </th>
            <th class="pointer" (click)="sortTable('stockDifference')">
              ✅ Diferencia
              <i class="fa-solid" [ngClass]="getSortIcon('stockDifference')"></i>
            </th>
            <th class="pointer" (click)="sortTable('stockDeficit')">
              Déficit ($)
              <i class="fa-solid" [ngClass]="getSortIcon('stockDeficit')"></i>
            </th>
            <th class="pointer" (click)="sortTable('stockSurplus')">
              Excedente ($)
              <i class="fa-solid" [ngClass]="getSortIcon('stockSurplus')"></i>
            </th>
            <th class="pointer" (click)="sortTable('salePrice')">
              Precio de Venta ($)
              <i class="fa-solid" [ngClass]="getSortIcon('salePrice')"></i>
            </th>
            <th *ngIf="inventory.status === 2">Ajuste</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody class="detail">
          <tr *ngFor="let record of inventoryRecords; let i = index">
            <td>{{ i + 1 }}</td>
            <td class="text-wrap" [innerHtml]="
                _productsService.getProductName(record.productUid, products)
              ">
              {{ _productsService.getProductName(record.productUid, products) }}
            </td>
            <!-- <td>{{ record.purchasePrice | currency }}</td> -->
            <td>{{ record.theoreticalStock }}</td>
            <td>{{ record.physicalStock }}</td>
            <td [ngClass]="{
                'negative-value': record.stockDifference < 0,
                'text-success fw-bold': record.stockDifference > 0
              }">
              {{ record.stockDifference }}
            </td>
            <td [ngClass]="{ 'text-danger fw-bold': record.stockDeficit > 0 }">
              {{ record.stockDeficit | currency }}
            </td>
            <td [ngClass]="{ 'text-success fw-bold': record.stockSurplus > 0 }">
              {{ record.stockSurplus | currency }}
            </td>
            <td>{{ record.salePrice | currency }}</td>
            <td *ngIf="inventory.status === 2" class="text-center p-2">
              <div class="form-check d-flex justify-content-center">
                <input class="form-check-input custom-checkbox" type="checkbox" [(ngModel)]="record.adjustment"
                  (click)="updateInventoryDetailAdjustment(record)" [disabled]="
                    record.stockSurplus <= 0 && record.stockDeficit <= 0
                  " />
              </div>
            </td>
            <td>
              <button class="btn btn-primary btn-sm me-1" [disabled]="inventory.status == 2"
                (click)="inventoryRecordDetail(content, record)">
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
              <button class="btn btn-danger btn-sm" [disabled]="inventory.status == 2"
                (click)="deleteInventoryRecordByUid(record.uid)">
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <!-- FOOTER DE LA TABLA PARA MOSTRAR TOTALES -->
        <tfoot class="table-dark">
          <tr>
            <td colspan="5" class="text-end fw-bold">Totales:</td>
            <td class="text-danger fw-bold">
              {{ getTotalDeficit() | currency }}
            </td>
            <td class="text-success fw-bold">
              {{ getTotalSurplus() | currency }}
            </td>
            <td colspan="3"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Producto</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeAddProductToInventoryModal()"></button>
  </div>
  <div class="modal-body">
    <app-stock-form [inventoryRecord]="inventoryRecordSelected" [categories]="categories"
      (closeStockFormModal)="closeStockFormModal($event)"></app-stock-form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="closeAddProductToInventoryModal()">
      Cerrar
    </button>
  </div>
</ng-template>