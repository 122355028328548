import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs/operators';

@Component({
  selector: 'app-product-finder',
  templateUrl: './product-finder.component.html',
  styleUrls: ['./product-finder.component.scss'],
})
export class ProductFinderComponent implements OnInit {
  private previousData: any;
  @Input() options: string[] = [];
  @Input() productNameSelected: string;
  @Input() disabled: boolean;
  @Output() sendData: EventEmitter<string[]> = new EventEmitter();
  mySearchEngineForm = new FormControl();
  filteredOptions: Observable<string[]>;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  inputDisabled: boolean;

  constructor() {}

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((value) => this._filter(value).slice(0, 10))
    );
  };

  ngOnInit(): void {
    if (this.productNameSelected) {
      this.mySearchEngineForm.setValue(this.productNameSelected);
    }
    // if (this.productNameSelected) {
    //   this.mySearchEngineForm.setValue(this.productNameSelected);
    // } else {
    //   this.filteredOptions = this.mySearchEngineForm.valueChanges.pipe(
    //     startWith(''),
    //     map((value) => this._filter(value).slice(0, 10))
    //   );
    //   this.filteredOptions.subscribe((data) => {
    //     if (!this.areEqual(data, this.previousData)) {
    //       this.previousData = data;
    //     }
    //   });
    // }
  }

  private _filter(value: string): string[] {
    const filterValues = value
      .toLowerCase()
      .split(' ')
      .filter((val) => val.trim() !== '');

    if (filterValues.length === 0) {
      // Si el filtro está vacío, retorna todos los elementos
      return this.options.slice();
    }

    const filteredOptions = this.options.filter((option) => {
      const optionLower = option.toLowerCase();
      return filterValues.every((filter) => optionLower.includes(filter));
    });

    // Ordena alfabéticamente antes de retornar
    return filteredOptions.sort();
  }

  private areEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  optionSelected(event: any) {
    const selectedOption = event.target.value;
    this.sendData.emit(selectedOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.disabled) {
      if (changes.disabled.currentValue) {
        this.mySearchEngineForm.disable();
      } else {
        this.mySearchEngineForm.enable();
      }
    }
  }
}
