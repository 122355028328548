<div class="container">
  <div class="row">
    <div class="col-4">
      <div class="d-flex justify-content-start pt-2 pb-2">
        <app-back-button [mini]="true"></app-back-button>
      </div>
    </div>
    <div class="col-4">
      <div class="d-flex justify-content-center pt-2 pb-2">
        <button class="btn btn-primary btn-sm" (click)="createInventory()">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="mt-5 mb-5 pt-5 pb-5" *ngIf="loading">
    <div class="d-flex align-items-center">
      <strong>Loading...</strong>
      <div
        class="spinner-border ms-auto"
        role="status"
        aria-hidden="true"
      ></div>
    </div>
  </div>
  <div class="table-responsive mt-3" *ngIf="!loading">
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-striped">
        <thead class="table-dark">
          <tr>
            <th># Inventario - Fecha Creación</th>
            <th>Estado</th>
            <th>Última Modificación</th>
            <th>Total de productos</th>
            <th>Total invertido ($)</th>
            <th>Valor en Venta ($)</th>
            <th class="text-danger">Déficit de Stock ($)</th>
            <th class="text-success">Excedente de Stock ($)</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let inventory of inventories">
            <td>
              <b> #{{ inventory.inventoryNumber }} </b> -
              {{ getDateWithMoment(inventory.createdAt, "ll") }}
            </td>
            <td>
              <ng-container *ngIf="inventory.status === 1">
                <span class="badge bg-secondary">En curso</span>
              </ng-container>
              <ng-container *ngIf="inventory.status === 2">
                <span class="badge bg-success">Finalizado</span>
              </ng-container>
            </td>
            <td *ngIf="inventory.updatedAt">
              {{ inventory.updatedAt | date }}
            </td>
            <td *ngIf="!inventory.updatedAt">No disponible</td>
            <td>{{ inventory.totalUnits }}</td>
            <td>{{ inventory.totalInvestmentValue | currency }}</td>
            <td>{{ inventory.totalValue | currency }}</td>
            <td class="text-danger">{{ inventory.stockDeficit | currency }}</td>
            <td class="text-success">
              {{ inventory.stockSurplus | currency }}
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm me-1"
                (click)="viewInventoryDetails(inventory.uid)"
              >
                <i class="fas fa-eye"></i>
              </button>
              <button
                class="btn btn-danger btn-sm"
                (click)="deleteInventory(inventory.uid)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
